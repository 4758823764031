define('wnyc-web-client/adapters/application', ['exports', 'ember-data', 'wnyc-web-client/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    },

    host: _environment.default.publisherAPI,
    namespace: 'v3',
    // ember 2.0 deprecation
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });
});