define('wnyc-web-client/services/discover-queue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    items: Ember.computed.alias('session.data.discover-queue'),
    history: Ember.inject.service('listen-history'),
    count: Ember.computed.alias('session.data.discover-queue.length'),
    hifi: Ember.inject.service(),
    dj: Ember.inject.service(),
    actionQueue: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var hifi = Ember.get(this, 'hifi');
      var actionQueue = Ember.get(this, 'actionQueue');

      actionQueue.addAction(hifi, 'audio-ended', { priority: 2, name: 'discover-queue' }, Ember.run.bind(this, this.onTrackFinished));
    },


    /* DISCOVER QUEUE -----------------------------------------------------------*/

    onTrackFinished: function onTrackFinished(sound) {
      if (Ember.get(sound, 'metadata.playContext') === 'discover') {
        var nextTrack = this.nextItem(Ember.get(this, 'dj.currentContentId'));
        if (nextTrack) {
          var dj = this.get('dj');
          dj.play(Ember.get(nextTrack, 'id'), { playContext: 'discover' });
          return true;
        }
      }
    },
    addItem: function addItem(item) {
      var session = this.get('session');
      var discoverQueue = session.getWithDefault('data.discover-queue', Ember.A());

      discoverQueue.pushObject(item);
      session.set('data.discover-queue', discoverQueue);
    },
    removeItem: function removeItem(item) {
      var session = this.get('session');
      var discoverQueue = session.getWithDefault('data.discover-queue', Ember.A());

      discoverQueue.removeObject(item);
      session.set('data.discover-queue', discoverQueue);
    },
    removeItemById: function removeItemById(id) {
      var session = this.get('session');
      var discoverQueue = session.getWithDefault('data.discover-queue', Ember.A());

      var item = discoverQueue.findBy('id', id);
      this.removeItem(item);
    },
    updateQueue: function updateQueue(items) {
      var session = this.get('session');
      session.set('data.discover-queue', items);
    },
    emptyQueue: function emptyQueue() {
      var session = this.get('session');
      session.set('data.discover-queue', []);
    },
    nextItem: function nextItem(currentPk) {
      var items = this.get('items');
      var current = this.get('items').findBy('id', currentPk);
      var currentIndex = items.indexOf(current);

      if (currentIndex < items.length) {
        return items[currentIndex + 1];
      }
    },
    nextUnplayedItem: function nextUnplayedItem() {
      var history = this.get('history');
      return this.get('items').find(function (item) {
        return !history.hasListenedTo(item.id);
      });
    }
  });
});