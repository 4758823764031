define('wnyc-web-client/discover/stories/model', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    title: (0, _attr.default)('string'),
    headers: (0, _attr.default)(),
    tease: (0, _attr.default)('string'),
    showTitle: Ember.computed.reads('headers.brand.title'),
    showUrl: Ember.computed.reads('headers.brand.url'),
    summary: Ember.computed.reads('tease'),
    slug: (0, _attr.default)('string'),
    newsdate: (0, _attr.default)('string'),
    estimatedDuration: (0, _attr.default)('number'),
    audioDurationReadable: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    audio: (0, _attr.default)('string'),
    cmsPK: (0, _attr.default)('string'),

    // so Ember Simple Auth inludes a records ID when it saves
    toJSON: function toJSON() {
      var serializer = this.store.serializerFor(this._internalModel.modelName);
      var snapshot = this._internalModel.createSnapshot();
      var serialized = serializer.serialize(snapshot, { includeId: true });
      return serialized;
    }
  });
});