define('wnyc-web-client/controllers/signup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['email', 'first', 'last'],
    email: null,
    first: null,
    last: null
  });
});