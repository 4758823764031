define('wnyc-web-client/components/story-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['story-card'],
    attributeBindings: ['target', 'href', 'data-category', 'data-action', 'data-label'],
    target: Ember.computed('href', function () {
      var url = this.get('href');
      url = new URL(url);
      return url.origin !== location.origin ? '_blank' : null;
    })
  });
});