define('wnyc-web-client/models/about-page', ['exports', 'nypr-publisher-lib/models/about-page'], function (exports, _aboutPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _aboutPage.default;
    }
  });
});