define('wnyc-web-client/routes/timeline', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('cssPath', _environment.default.staticAssetPath + 'css/timeline-2020406130600.css');
      return this._super.apply(this, arguments);
    },

    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });
});