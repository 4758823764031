define('wnyc-web-client/routes/profile', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Profile',

    currentUser: Ember.inject.service(),

    model: function model() {
      return this.get('currentUser.user');
    },
    setupController: function setupController(controller, model) {
      controller.send('updateEmailStatus', Ember.get(model, 'email'));
      return this._super(controller, model);
    },

    actions: {
      didTransition: function didTransition() {
        //shows the zendesk widget if prev loaded
        if (window.zE && typeof window.zE.show === "function") {
          window.zE.show();
        }
      },
      willTransition: function willTransition() {
        //hide the zendesk Widget
        if (window.zE && typeof window.zE.hide === "function") {
          window.zE.hide();
        }
      }
    }
  });
});