define('wnyc-web-client/components/ivy-tabs-tablist', ['exports', 'ivy-tabs/components/ivy-tabs-tablist'], function (exports, _ivyTabsTablist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ivyTabsTablist.default;
    }
  });
});