define('wnyc-web-client/components/discover-playlist-story/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['discover-playlist-story'],
    classNameBindings: ['isLoading', 'isPlaying', 'isCurrentTrack'],
    mouseLeave: function mouseLeave() {
      this.set('isHovering', false);
    },
    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    }
  });
});