define('wnyc-web-client/components/story-card/image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['story-card__image'],
    attributeBindings: ['style'],
    style: Ember.computed('src', function () {
      var src = this.get('src');
      return Ember.String.htmlSafe('background-image: url(' + src + ')');
    })
  });
});