define('wnyc-web-client/helpers/list-separator', ['exports', 'nypr-ui/helpers/list-separator'], function (exports, _listSeparator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _listSeparator.default;
    }
  });
  Object.defineProperty(exports, 'imageTemplate', {
    enumerable: true,
    get: function () {
      return _listSeparator.imageTemplate;
    }
  });
});