define('wnyc-web-client/routes/verify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Verify',

    actions: {
      didTransition: function didTransition() {
        this.send('disableChrome');
      },
      willTransition: function willTransition() {
        this.send('enableChrome');
      }
    }
  });
});