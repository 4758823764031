define('wnyc-web-client/routes/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _emberData, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DETAIL_ROUTES = new RegExp(/story|(show|article|series|tag|blog)-detail\./);

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    dataPipeline: Ember.inject.service(),
    asyncWriter: Ember.inject.service(),
    legacyLoader: Ember.inject.service(),
    leaderboard: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    poll: Ember.inject.service(),
    store: Ember.inject.service(),
    dj: Ember.inject.service(),

    title: function title() {
      var _this = this;

      var tokens = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var siteName = 'WNYC';
      var tagline = 'New York Public Radio, Podcasts, Live Streaming Radio, News';

      // combine the first two items if the second item stats with `:`
      if (tokens[1] && tokens[1].startsWith(':')) {
        tokens.splice(0, 2, '' + tokens[0] + tokens[1]);
      }

      tokens.push(siteName);
      if (tokens.length < 3) {
        tokens.push(tagline);
      }
      var title = tokens.join(' | ');
      Ember.get(this, 'dataLayer').setPageTitle(title);

      if (!this.controller._wasModal) {
        var route = this.router.currentRouteName;

        Ember.run.schedule('afterRender', function () {
          Ember.get(_this, 'dataLayer').sendPageView();
          if (!DETAIL_ROUTES.test(route) && !route.match(/loading/)) {
            _this.get('dataPipeline').reportItemView();
          }
        });
      } else {
        // reset
        this.controller._wasModal = false;
      }

      return title;
    },
    beforeModel: function beforeModel() {
      var _this2 = this;

      Ember.get(this, 'session').syncBrowserId().then(function (id) {
        return Ember.get(_this2, 'dj').addBrowserId(id);
      });
      Ember.get(this, 'session').staffAuth();
      Ember.get(this, 'currentUser').load();

      Ember.get(this, 'dataLayer').setLoggedIn(false);

      Ember.get(this, 'asyncWriter').install();
      Ember.get(this, 'leaderboard').install();

      window.WNYC_LEGACY_LOADER = Ember.get(this, 'legacyLoader');

      var pollFunction = function pollFunction() {
        return Ember.get(_this2, 'store').findAll('stream');
      };
      Ember.get(this, 'poll').addPoll({ interval: 60 * 1000, callback: pollFunction });
    },


    actions: {
      error: function error(_error /*, transition*/) {
        if (_error instanceof _emberData.default.NotFoundError) {
          this.transitionTo('404', _error.url);
        } else {
          /* eslint-disable */
          console.error(_error);
          /* eslint-enable */
        }
      },
      didTransition: function didTransition() {
        this.set('dataPipeline.currentReferrer', window.location.toString());
      },
      willTransition: function willTransition() {
        //close queue/history modal when we open a new page
        this.controllerFor('application').send('closeModal');
        this.send('updateDonateChunk', null);
      },
      updateDonateChunk: function updateDonateChunk(donateChunk) {
        this.controllerFor('application').set('headerDonateChunk', donateChunk);
      },
      setMiniChrome: function setMiniChrome(val) {
        this.controllerFor('application').set('miniChrome', val);
      },
      disableChrome: function disableChrome() {
        this.controllerFor('application').set('chromeDisabled', true);
      },
      enableChrome: function enableChrome() {
        this.controllerFor('application').set('chromeDisabled', false);
      }
    },

    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      Ember.get(this, 'dataLayer').setLoggedIn(true);
      Ember.get(this, 'currentUser').load();
    },
    sessionInvalidated: function sessionInvalidated() {
      Ember.get(this, 'dataLayer').setLoggedIn(false);
      if (this.get('session.noRefresh') === true) {
        this.set('session.noRefresh', false);
      } else {
        this._super.apply(this, arguments);
      }
    }
  });
});