define('wnyc-web-client/controllers/verify', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    config: _environment.default,
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ['email_id', 'verification_token'],
    email_id: null,
    verification_token: null,
    actions: {
      goToProfile: function goToProfile(errorMessage) {
        var flashMessages = this.get('flashMessages');
        if (!errorMessage) {
          flashMessages.add({
            message: 'Your email address has been successfully verified',
            type: 'success'
          });
        } else {
          flashMessages.add({
            message: errorMessage,
            type: 'warning'
          });
        }
        this.transitionToRoute('profile');
      }
    }
  });
});