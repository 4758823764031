define('wnyc-web-client/discover/start/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    discoverPrefs: Ember.inject.service(),

    setupController: function setupController(controller) {
      controller.set('isMobile', window.Modernizr.touchevents);
      controller.set('isAndroid', /Android/i.test(window.navigator.userAgent));
      return this._super.apply(this, arguments);
    },
    redirect: function redirect(model, transition) {
      var prefs = this.get('discoverPrefs');

      if (!prefs.get('setupComplete')) {
        this.transitionTo('discover.' + prefs.get('currentSetupStep'));
      } else if (transition.targetName === 'discover.start') {
        // we clicked on the side bar, and setup is done. Go to the playlist
        this.replaceWith('discover.index');
      }
    },

    actions: {
      next: function next() {
        var prefs = this.get('discoverPrefs');
        prefs.set('currentSetupStep', 'topics');
        prefs.save();

        this.transitionTo('discover.topics');
      }
    }
  });
});