define('wnyc-web-client/components/nypr-accounts/password-card', ['exports', 'nypr-account-settings/components/nypr-accounts/password-card'], function (exports, _passwordCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _passwordCard.default;
    }
  });
});