define("wnyc-web-client/services/listen-analytics", ["exports", "nypr-metrics/services/listen-analytics"], function (exports, _listenAnalytics) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _listenAnalytics.default;
    }
  });
});