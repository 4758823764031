define('wnyc-web-client/routes/validate', ['exports', 'wnyc-web-client/mixins/deauthenticated-route-mixin'], function (exports, _deauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_deauthenticatedRouteMixin.default, {
    titleToken: 'Validate',

    actions: {
      didTransition: function didTransition() {
        this.send('disableChrome');
      },
      willTransition: function willTransition() {
        this.send('enableChrome');
      }
    }
  });
});