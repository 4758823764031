define('wnyc-web-client/helpers/link-or-text', ['exports', 'nypr-ui/helpers/link-or-text'], function (exports, _linkOrText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkOrText.default;
    }
  });
  Object.defineProperty(exports, 'linkOrText', {
    enumerable: true,
    get: function () {
      return _linkOrText.linkOrText;
    }
  });
});