define('wnyc-web-client/transitions/notification-reveal', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    if (this.newValue) {
      this.newElement.css('visibility', '');
      var notification = this.newElement.find('.js-player-notification');
      notification.css({ transform: 'translateY(0)', 'z-index': 1200 });
      var notificationAnimationOptions = { easing: [0.17, 0.89, 1, 1], duration: 500 };
      return (0, _liquidFire.animate)(notification, { translateY: [0, '80px'] }, notificationAnimationOptions);
    } else {
      var _notification = this.oldElement.find('.js-player-notification');
      _notification.css({ transform: 'translateY(80px)', 'z-index': 1200 });
      var _notificationAnimationOptions = { easing: [0.17, 0.89, 1, 1], duration: 500 };
      return (0, _liquidFire.animate)(_notification, { translateY: ['80px', 0] }, _notificationAnimationOptions);
    }
  };
});