define('wnyc-web-client/stream/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    googleAds: Ember.inject.service(),

    titleToken: 'Listen Live to WNYC, WQXR, New Sounds, Operavore, NJPR, and American Standards',

    model: function model() {
      return this.store.findAll('stream', { reload: true }).then(function (streams) {
        return streams.filterBy('isWNYC').sortBy('sitePriority').concat(streams.filterBy('isWQXR').sortBy('sitePriority')).uniq();
      });
    },
    afterModel: function afterModel() {
      this.get('googleAds').doTargeting();
    }
  });
});