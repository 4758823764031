define('wnyc-web-client/components/sticky-page-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    spacerHeight: '0px',

    spacerStyle: Ember.computed('spacerHeight', function () {
      return Ember.String.htmlSafe('height: ' + this.get('spacerHeight') + ';');
    }),

    willUpdate: function willUpdate() {
      var header = this.$('.sticky-page-header')[0];
      var height = window.getComputedStyle(header).height;
      if (!this.get('sticky')) {
        height = '0px';
      }
      this.set('spacerHeight', height);
    },


    actions: {
      trigger: function trigger(direction) {
        var sticky = direction === 'down';
        this.set('sticky', sticky);
      }
    }
  });
});