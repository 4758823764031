define('wnyc-web-client/adapters/show', ['exports', 'nypr-publisher-lib/adapters/show', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _show, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _show.default.extend(_dataAdapterMixin.default, {
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    }
  });
});