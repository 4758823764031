define('wnyc-web-client/discover/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    discoverQueue: Ember.inject.service(),
    discoverPrefs: Ember.inject.service(),
    scroller: Ember.inject.service(),
    titleToken: 'Discover Playlist',

    hasQueuedStories: Ember.computed.gt('discoverQueue.items.length', 0),

    setupController: function setupController(controller) {
      controller.set('noNewResults', false);
      return this._super.apply(this, arguments);
    },
    model: function model() {
      var stories;
      if (this.get('hasQueuedStories')) {
        stories = this._loadStoriesFromQueue();
      } else {
        stories = this._loadStoriesFromServer();
      }

      return Ember.RSVP.hash({ stories: stories });
    },
    afterModel: function afterModel(model) {
      this._updateDiscoverQueue(model.stories);
    },
    _loadStoriesFromQueue: function _loadStoriesFromQueue() {
      var prefs = this.get('discoverPrefs');
      var excludedIds = prefs.get('excludedStoryIds');
      var queuedStories = this.get('discoverQueue.items');

      return queuedStories.reject(function (story) {
        return excludedIds.includes(story.id);
      });
    },
    _loadStoriesFromServer: function _loadStoriesFromServer() {
      var stories;
      var prefs = this.get('discoverPrefs');
      var excludedIds = prefs.get('excludedStoryIds');
      var topicTags = prefs.get('selectedTopicTags');
      var excludedShowSlugs = prefs.get('excludedShowSlugs');

      stories = this.store.query('discover.stories', {
        shows: excludedShowSlugs.join(","),
        tags: topicTags.join(","),
        duration: 10800,
        _nocache: Date.now(),
        'fields[story]': 'title,headers,estimated_duration,audio_duration_readable,newsdate,tease,audio,cms_pk,slug'
      }).then(function (stories) {
        return stories.reject(function (s) {
          return excludedIds.includes(s.id);
        });
      });

      return stories;
    },
    _updateDiscoverQueue: function _updateDiscoverQueue(stories) {
      // The queue is the up to date list of items that should be visible in the playlist,
      // but the playlist holds on to deleted items a little longer in order to hide them with css effects

      // If the stories in the playlist get bound to the queue, then when we remove an item
      // from the queue the playlist will yank that sucker right out without
      // doing our super sweet CSS effect. That's why we do a .copy() right here.

      this.get('discoverQueue').updateQueue(stories.copy());
    },
    _hasNoNewResults: function _hasNoNewResults(stories) {
      if (Ember.isEmpty(stories)) {
        return true;
      } else {
        var oldStoryIds = this._loadStoriesFromQueue().mapBy('id');
        var newStoryIds = stories.mapBy('id');
        return Ember.isEmpty(newStoryIds.reject(function (s) {
          return oldStoryIds.includes(s);
        }));
      }
    },


    actions: {
      findMore: function findMore() {
        var _this = this;

        var controller = this.controllerFor('discover.index');

        controller.set('findingMore', true);
        controller.set('noNewResults', false);

        this._loadStoriesFromServer().then(function (stories) {
          if (_this._hasNoNewResults(stories)) {
            controller.set('noNewResults', true);
            return _this._loadStoriesFromQueue();
          } else {
            return stories;
          }
        }).then(function (s) {
          _this.set('currentModel.stories', s);
          _this._updateDiscoverQueue(s);
        }).finally(function () {
          _this.get('scroller').scrollVertical('.sitechrome-top', { duration: 500 });
          controller.set('findingMore', false);
        });
      },
      removeItem: function removeItem(item) {
        var prefs = this.get('discoverPrefs');
        var itemId = Ember.get(item, 'id');

        this.get('discoverQueue').removeItem(item);

        // Make sure this doesn't show up again
        prefs.excludeStoryId(itemId);
      },
      updateItems: function updateItems(items) {
        this._updateDiscoverQueue(items);
      },
      edit: function edit() {
        this.transitionTo('discover.edit');
      }
    }
  });
});