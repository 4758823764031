define('wnyc-web-client/components/player-notification', ['exports', 'nypr-audio-services/components/player-notification'], function (exports, _playerNotification) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _playerNotification.default;
    }
  });
});