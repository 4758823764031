define('wnyc-web-client/utils/fix-positioning-after-transition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fixPositioningAfterTransition;
  function fixPositioningAfterTransition() {
    Ember.run.later(null, function () {
      window.$('.liquid-child').css('transform', 'initial').css('-moz-transform', 'initial').css('-webkit-transform', 'initial').css('overflow', 'hidden');
    }, 250);
  }
});