define('wnyc-web-client/components/centennial/centennial-toggletip/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var TOOLTIP_ID = 'tool1';

  exports.default = Ember.Component.extend({
    tooltipIsOpen: false,
    buttonAriaExpanded: Ember.computed.readOnly('tooltipIsOpen'),
    buttonAriaDescribedBy: Ember.computed('tooltipIsOpen', function () {
      return this.tooltipIsOpen ? TOOLTIP_ID : '';
    }),
    tooltipClass: Ember.computed('tooltipIsOpen', function () {
      return this.tooltipIsOpen ? 'mod-visible' : 'mod-hidden';
    }),

    actions: {
      closeTooltip: function closeTooltip() {
        Ember.set(this, 'tooltipIsOpen', false);
      },
      toggleTooltip: function toggleTooltip() {
        if (!this.tooltipIsOpen) {
          Ember.set(this, 'tooltipIsOpen', true);
        } else {
          Ember.set(this, 'tooltipIsOpen', false);
        }
      }
    }
  });
});