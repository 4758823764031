define('wnyc-web-client/discover/topics/adapter', ['exports', 'wnyc-web-client/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-data'], function (exports, _environment, _dataAdapterMixin, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.publisherAPI,
    namespace: 'v1/discover/topics/',
    buildURL: function buildURL() {
      return [this.host, this.namespace].join('/');
    },
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    }
  });
});