define('wnyc-web-client/utils/math-util', ['exports', 'nypr-publisher-lib/utils/math-util'], function (exports, _mathUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'totalPages', {
    enumerable: true,
    get: function () {
      return _mathUtil.totalPages;
    }
  });
  Object.defineProperty(exports, 'calculateOffset', {
    enumerable: true,
    get: function () {
      return _mathUtil.calculateOffset;
    }
  });
  Object.defineProperty(exports, 'firstLessThan', {
    enumerable: true,
    get: function () {
      return _mathUtil.firstLessThan;
    }
  });
});