define('wnyc-web-client/components/centennial/centennial-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['centennial-card'],
    attributeBindings: ['target', 'href', 'data-category', 'data-action', 'data-label'],
    url: Ember.computed('this.item', function () {
      return this.item.attributes.url;
    }),
    title: Ember.computed('this.item', function () {
      return this.item.attributes.title;
    }),
    image: Ember.computed('this.item', function () {
      return this.item.attributes.imageMain || this.item.attributes.image;
    }),
    tease: Ember.computed('this.item', function () {
      return this.item.attributes.tease;
    }),
    isStreamable: Ember.computed.and('item.attributes.audioAvailable', 'item.attributes.audioMayStream')
  });
});