define('wnyc-web-client/discover/stories/adapter', ['exports', 'wnyc-web-client/config/environment', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _environment, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var path = 'make_playlist';

  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.publisherAPI,
    namespace: 'v3/' + path + '/',
    pathForType: function pathForType() {
      return '';
    },
    session: Ember.inject.service(),

    ajaxOptions: function ajaxOptions(url, type, options) {
      options.data.discover_station = _environment.default.discoverStation;
      options.data.api_key = _environment.default.discoverAPIKey;
      options.data.browser_id = this.get('session.data.browserId');
      options.xhrFields = {
        withCredentials: true
      };
      return this._super(url, type, options);
    },
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    }
  });
});