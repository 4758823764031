define("wnyc-web-client/transitions/discover-start", ["exports", "wnyc-web-client/transitions/move-over", "liquid-fire", "wnyc-web-client/utils/fix-positioning-after-transition", "velocity"], function (exports, _moveOver, _liquidFire, _fixPositioningAfterTransition, _velocity) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = initialTransition;
  function initialTransition() {
    var _this = this;

    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var background = window.$(".discover-fadeable-background");
    var el = document.getElementsByTagName('html');

    if (!this.oldElement && window.innerWidth >= 1025) {
      // initial render. A little hacky, because we set that background
      // image to 0 opacity in the dom and have to modify it now. That's
      background.css('opacity', 1);
      this.newElement.show();

      return (0, _liquidFire.animate)(this.newElement, { opacity: 1, duration: 0 });
    } else if (this.oldElement && this.oldElement.find('.discover-welcome-screen').length > 0) {
      return (0, _velocity.default)(el, 'scroll', { offset: 0, duration: 100 }).then(function () {
        return (0, _liquidFire.animate)(background, { opacity: 0, duration: 0.5 }, opts).then(function () {
          return _moveOver.default.call(_this, 'x', -1).then(function () {
            (0, _fixPositioningAfterTransition.default)();
          });
        });
      });
    } else {
      this.newElement.show();
      background.css('opacity', 0);
      return (0, _velocity.default)(el, 'scroll', { offset: 0, duration: 100 }).then(function () {
        return _moveOver.default.call(_this, 'x', 1).then(function () {
          return (0, _liquidFire.animate)(background, { opacity: 1, duration: 0.5 }, opts).then(function () {
            (0, _fixPositioningAfterTransition.default)();
          });
        });
      });
    }
  }
});