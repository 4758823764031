define('wnyc-web-client/components/embedded/header-search-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',
    elementId: 'search-form',
    classNames: ['promoted-layer', 'animate-transforms'],

    submit: function submit(e) {
      e.preventDefault();
      this.get('search')(this.get('q'));
    }
  });
});