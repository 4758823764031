define('wnyc-web-client/controllers/set-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['username', 'code', 'email', 'verification_token', 'email_id'],
    username: null,
    code: null,
    email: null,
    verification_token: null,
    email_id: null
  });
});