define('wnyc-web-client/components/go-to-gothamist-banner/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['go-to-gothamist-banner'],
    attributeBindings: ['href', 'target', 'data-category', 'data-action', 'data-label'],
    href: 'http://gothamist.com?utm_medium=partnersite&utm_source=wnyc&utm_campaign=banner',
    target: '_blank'
  });
});