define('wnyc-web-client/components/error-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    errorType: Ember.computed.readOnly('error.response.status'),
    pageNotFound: Ember.computed.equal('errorType', 404),
    serverError: Ember.computed.equal('errorType', 500)
  });
});