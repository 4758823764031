define('wnyc-web-client/helpers/append-query', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.appendQuery = appendQuery;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function appendQuery(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 2),
        _ref2$ = _ref2[0],
        url = _ref2$ === undefined ? '' : _ref2$,
        _ref2$2 = _ref2[1],
        queryToAdd = _ref2$2 === undefined ? '' : _ref2$2;

    if (typeof url !== 'string' || typeof queryToAdd !== 'string') {
      return url;
    }

    // string any beginning ? characters
    queryToAdd = queryToAdd.replace(/^\?/, '');

    if (/\?$/.test(url)) {
      // incoming url ends with a ?, just jam on the query string
      return '' + url + queryToAdd;
    }

    // does a query string exist?

    var _url$split = url.split('?'),
        _url$split2 = _toArray(_url$split),
        foundQuery = _url$split2[1],
        extra = _url$split2.slice(2);

    if (extra.length > 0) {
      // found a query string in the url or the query params
      // weird case, just bail out
      return url;
    }

    if (foundQuery) {
      // there's already a query string on the url, add it to the params
      return url + '&' + queryToAdd;
    } else {
      // no query string, make one
      return url + '?' + queryToAdd;
    }
  }

  exports.default = Ember.Helper.helper(appendQuery);
});