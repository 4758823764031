define('wnyc-web-client/discover/topics/serializer', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      /* Data comes back as
        { pk: 125125124,
         links: [
            {url: 'a-a', title: 'Arts'},
            etc
        ]}
      */
      var data = payload.links.map(function (topic) {
        return {
          id: payload.pk + '_' + topic.url,
          type: 'discover/topics',
          attributes: topic
        };
      });

      return {
        data: data
      };
    }
  });
});