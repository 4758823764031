define('wnyc-web-client/services/leaderboard', ['exports', 'nypr-ads/services/leaderboard'], function (exports, _leaderboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _leaderboard.default;
    }
  });
});