define('wnyc-web-client/discover/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    discoverPrefs: Ember.inject.service(),
    discoverQueue: Ember.inject.service(),

    redirect: function redirect(model, transition) {
      if (transition.targetName === "discover.edit.index") {
        this.transitionTo('discover.edit.topics');
      }
    },

    actions: {
      noShowsSelected: function noShowsSelected(hasNotSelectedAShow) {
        var parentController = this.controller;
        parentController.set('hasNotSelectedAShow', hasNotSelectedAShow);
        this.send('showError');
      },
      updateShowSelection: function updateShowSelection(excludedShowSlugs) {
        var prefs = this.get('discoverPrefs');
        prefs.set('excludedShowSlugs', excludedShowSlugs);
      },
      noTopicsSelected: function noTopicsSelected(hasNotSelectedATopic) {
        var parentController = this.controller;
        parentController.set('hasNotSelectedATopic', hasNotSelectedATopic);
        this.send('showError');
      },
      updateTopicSelection: function updateTopicSelection(selectedTopicTags) {
        var prefs = this.get('discoverPrefs');
        prefs.set('selectedTopicTags', selectedTopicTags);
      },
      showError: function showError() {
        var parentController = this.controller;
        var error = parentController.get('hasNotSelectedATopic') || parentController.get('hasNotSelectedAShow');
        this.controller.set('showError', error);
      },
      cancel: function cancel() {
        this.get('discoverPrefs').discard();
        this.transitionTo('discover.index');
      },
      refresh: function refresh() {
        var parentController = this.controller;
        var error = parentController.get('hasNotSelectedATopic') || parentController.get('hasNotSelectedAShow');
        if (error) {
          return;
        }

        this.get('discoverPrefs').save();
        this.get('discoverQueue').emptyQueue();
        this.transitionTo('discover.index');
      },
      loading: function loading(transition) {
        try {
          var controller = this.controllerFor('discover.index');
          controller.set('isLoading', true);
          transition.promise.finally(function () {
            return controller.set('isLoading', false);
          });
        } catch (e) {}
        return true;
      }
    }
  });
});