define('wnyc-web-client/mixins/listing-route', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var inflect = new _emberInflector.default(_emberInflector.default.defaultRules);

  exports.default = Ember.Mixin.create({
    pageNumbers: Ember.inject.service(),
    dataPipeline: Ember.inject.service(),
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),

    titleToken: function titleToken() {
      var channelType = Ember.get(this, 'channelType');

      var _modelFor = this.modelFor(channelType),
          channel = _modelFor.channel;

      return Ember.get(channel, 'title');
    },
    beforeModel: function beforeModel() {
      var channelType = this.routeName.split('.')[0];
      var channelPathName = inflect.pluralize(channelType.split('-')[0]);
      Ember.setProperties(this, { channelType: channelType, channelPathName: channelPathName });
    },
    afterModel: function afterModel(model) {
      var channelType = Ember.get(this, 'channelType');

      var _modelFor2 = this.modelFor(channelType),
          channel = _modelFor2.channel;

      var dataPipeline = Ember.get(this, 'dataPipeline');

      Ember.get(this, 'dataLayer').setForType(Ember.get(channel, 'itemType'), channel);

      // data pipeline
      dataPipeline.reportItemView({
        cms_id: channel.get('cmsPK'),
        item_type: channel.get('listingObjectType')
      });

      Ember.$('main > section:last-of-type').css('opacity', 1);
      var teaseList = Ember.get(model, 'teaseList');
      if (Ember.isEmpty(teaseList)) {
        return;
      }

      this._filterForFeatured(teaseList, channel);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var channelType = Ember.get(this, 'channelType');

      var _modelFor3 = this.modelFor(channelType),
          channel = _modelFor3.channel;

      controller.setProperties({
        channelTitle: Ember.get(channel, 'title'),
        channelType: channelType,
        flipTease: Ember.get(channel, 'altLayout')
      });
    },


    actions: {
      loading: function loading() {
        Ember.$('main > section:last-of-type').css('opacity', 0.5);
      },
      pageNumberClicked: function pageNumberClicked(page) {
        var channelType = Ember.get(this, 'channelType');
        var navSlug = this._getNavSlug(channelType);
        this._scrollToOffset(channelType);
        this.transitionTo(channelType + '.page', navSlug ? navSlug + '/' + page : page);
      },
      willTransition: function willTransition() {
        var channelType = Ember.get(this, 'channelType');

        var _modelFor4 = this.modelFor(channelType),
            channel = _modelFor4.channel;

        Ember.get(this, 'dataLayer').clearForType(Ember.get(channel, 'itemType'));

        return true;
      }
    },

    _getNavSlug: function _getNavSlug(channelType) {
      var _modelFor5 = this.modelFor(channelType),
          channel = _modelFor5.channel;

      var _paramsFor = this.paramsFor(channelType + '.page'),
          page_params = _paramsFor.page_params;

      var _ref = page_params ? page_params.split('/') : [],
          _ref2 = _slicedToArray(_ref, 1),
          navSlug = _ref2[0];

      var linkRollSlug = Ember.get(channel, 'linkroll.firstObject.nav-slug');
      var hasLinkRoll = Ember.get(channel, 'hasLinkroll');

      if (hasLinkRoll && navSlug && !/^\d+$/.test(navSlug)) {
        return navSlug;
      } else if (hasLinkRoll && linkRollSlug) {
        return linkRollSlug;
      } else {
        return false;
      }
    },
    _filterForFeatured: function _filterForFeatured(teaseList, channel) {
      var channelHasFeatured = Ember.get(channel, 'featured');
      var featuredId = Ember.get(channel, 'featured.id');
      var featuredStory = teaseList.findBy('id', featuredId);

      if (channelHasFeatured) {
        teaseList.removeObject(featuredStory);
      }
    },
    _scrollToOffset: function _scrollToOffset(channelType) {
      var _modelFor6 = this.modelFor(channelType),
          channel = _modelFor6.channel;

      var hasLinkroll = Ember.get(channel, 'hasLinkroll');
      var scrollTarget = hasLinkroll ? Ember.$('nav.nav-links').get(0) : Ember.$('.channel-title').get(0);
      if (scrollTarget.scrollIntoView) {
        scrollTarget.scrollIntoView();
      } else {
        window.scrollTo(0, 0);
      }
    }
  });
});