define('wnyc-web-client/components/listen-button/ui', ['exports', 'nypr-audio-services/components/listen-button/ui'], function (exports, _ui) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ui.default;
    }
  });
});