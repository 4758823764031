define('wnyc-web-client/components/election-promo/opt-in/component', ['exports', 'nypr-election-countdown/components/election-promo/opt-in/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});