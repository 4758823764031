define('wnyc-web-client/components/x-modal/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['l-sliding-modal', 'l-modal--shadow'],
    didInsertElement: function didInsertElement() {
      Ember.$('body').addClass('is-open-modal');
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('body').removeClass('is-open-modal');
    },

    actions: {
      closeModal: function closeModal() {
        Ember.get(this, 'closeModal')();
      }
    }
  });
});