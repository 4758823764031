define('wnyc-web-client/discover/topics/route', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    discoverPrefs: Ember.inject.service(),
    titleToken: 'Discover Select Topics',

    model: function model() {
      return Ember.RSVP.hash({
        topics: this.store.query('discover.topics', { discover_station: _environment.default.discoverTopicsKey }),
        selectedTopicTags: this.get('discoverPrefs.selectedTopicTags')
      });
    },
    setupController: function setupController(controller /*, model*/) {
      this._super.apply(this, arguments);
      controller.set('loadingDirection', null);
    },


    actions: {
      back: function back(selectedTopicTags) {
        var prefs = this.get('discoverPrefs');
        prefs.set('currentSetupStep', 'start');
        prefs.set('selectedTopicTags', selectedTopicTags);

        this.controller.set('loadingDirection', 'back');
        this.transitionTo('discover.start');
      },
      next: function next(selectedTopicTags) {
        var prefs = this.get('discoverPrefs');
        if (selectedTopicTags.length === 0) {
          this.controller.set('showError', true);
        } else {
          this.controller.setProperties({ showError: false, loadingDirection: 'next' });

          this.controllerFor('discover.topics').set('showError', false);
          prefs.set('selectedTopicTags', selectedTopicTags);
          prefs.set('currentSetupStep', 'shows');
          prefs.save();

          this.transitionTo('discover.shows');
        }
      }
    }
  });
});