define('wnyc-web-client/components/centennial/centennial-header/component', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('socialIcons', [{ url: 'https://www.instagram.com/wnyc', icon: 'instagram' }, { url: 'http://x.com/wnyc', icon: 'x' }, { url: 'https://www.youtube.com/channel/UCbysmY4hyViQAAYEzOR-uCQ', icon: 'youtube' }, { url: 'http://www.facebook.com/wnyc', icon: 'facebook' }]);
      this.set('staticAssetPath', _environment.default.staticAssetPath);
    }
  });
});