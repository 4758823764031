define('wnyc-web-client/components/expandable-responsive-leaderboard', ['exports', 'nypr-ads/components/expandable-responsive-leaderboard'], function (exports, _expandableResponsiveLeaderboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _expandableResponsiveLeaderboard.default;
    }
  });
});