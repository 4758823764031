define('wnyc-web-client/components/popup-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['popupmenu'],
    classNameBindings: ['isOpen'],
    tagName: 'div',
    isOpen: false,
    popupStyle: Ember.String.htmlSafe(''),
    pointerStyle: Ember.String.htmlSafe(''),
    screenMargin: 5,
    clickOutside: function clickOutside() {
      this.send('closePopup');
    },

    actions: {
      togglePopup: function togglePopup() {
        if (Ember.get(this, 'isOpen')) {
          this.send('closePopup');
        } else {
          this.send('openPopup');
        }
      },
      openPopup: function openPopup() {
        Ember.set(this, 'isOpen', true);
        this._adjustPopupPosition();
      },
      closePopup: function closePopup() {
        Ember.set(this, 'isOpen', false);
      }
    },
    _adjustPopupPosition: function _adjustPopupPosition() {
      var popupElem = this.$('.popupmenu-popup')[0];

      // reset the style before calculations
      // default in css should be left: 0;
      // calculations assume popup starts left aligned with button
      Ember.set(this, 'popupStyle', Ember.String.htmlSafe(''));
      // wait for style change to propogate before calculating
      Ember.run.next(this, function () {
        var popupRect = popupElem.getBoundingClientRect();
        var buttonRect = this.$('.popupmenu-button')[0].getBoundingClientRect();

        // Align center of popup with center of button using popupOffset
        var popupOffset = this._calcMidpointOffset(buttonRect, popupRect);

        // get an additional offset to keep the popup onscreen;
        var offscreenOffset = this._calcOffscreenOffset(popupRect, popupOffset);

        // move the popup window.
        var popupStyle = Ember.String.htmlSafe('left: ' + (popupOffset + offscreenOffset) + 'px');
        Ember.set(this, 'popupStyle', popupStyle);

        // If we pushed the popup back onscreen, we need to move the pointer the
        // other way to keep it centered;
        if (offscreenOffset) {
          // 50% of popup width (center) - offscreenOffset to point the pointer
          // at the center of the button
          var pointerStyle = Ember.String.htmlSafe('left: ' + (this._calcWidth(popupRect) / 2 - offscreenOffset) + 'px');
          Ember.set(this, 'pointerStyle', pointerStyle);
        }
      });
    },
    _calcMidpointOffset: function _calcMidpointOffset(rect1, rect2) {
      //get the difference between the x midpoint of two position rects.
      return this._calcXMidpoint(rect1) - this._calcXMidpoint(rect2);
    },
    _calcOffscreenOffset: function _calcOffscreenOffset(rect, startingOffset) {
      // Taking a position rect and a startingOffset,
      // and accounting for screenMargin,
      // check if that rect is out of bounds of the viewportWidth,
      // and calculate an offscreenOffset to move rect back onscreen.
      var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var screenMargin = Ember.get(this, 'screenMargin');
      var offscreenOffset = 0;
      if (rect.left + startingOffset < screenMargin) {
        offscreenOffset = screenMargin - (rect.left + startingOffset);
      } else if (rect.right + startingOffset > viewportWidth - screenMargin) {
        offscreenOffset = viewportWidth - (rect.right + startingOffset + screenMargin);
      }
      return offscreenOffset;
    },
    _calcXMidpoint: function _calcXMidpoint(boundingRect) {
      return (boundingRect.right + boundingRect.left) / 2;
    },
    _calcWidth: function _calcWidth(boundingRect) {
      return boundingRect.right - boundingRect.left;
    }
  });
});