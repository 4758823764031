define("wnyc-web-client/transitions/discover-loading", ["exports", "wnyc-web-client/transitions/move-over", "liquid-fire", "wnyc-web-client/utils/fix-positioning-after-transition"], function (exports, _moveOver, _liquidFire, _fixPositioningAfterTransition) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = loadingTransition;
  function loadingTransition() {
    var _this = this;

    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var direction = 1;
    if (opts['direction'] === 'fromLeft') {
      direction = -1;
    }

    var loadingScreen = void 0;

    if (this.oldElement && this.oldElement.find('.discover-loading').length > 0) {
      // fade in loading screen, slide in other view
      loadingScreen = this.oldElement;
      // otherScreen = this.newElement;

      return (0, _liquidFire.animate)(loadingScreen, { opacity: 0, duration: 0.5 }, opts).then(function () {
        return _moveOver.default.call(_this, 'x', -1 * direction).then(function () {
          (0, _fixPositioningAfterTransition.default)();
        });
      });
    } else {
      // slide out other view, fade in loading screen
      loadingScreen = this.newElement;
      // otherScreen = this.oldElement;

      loadingScreen.css('opacity', 0);
      return _moveOver.default.call(this, 'x', 1 * direction).then(function () {
        return (0, _liquidFire.animate)(loadingScreen, { opacity: 1, duration: 0.5 }, opts).then(function () {
          (0, _fixPositioningAfterTransition.default)();
        });
      });
    }
  }
});