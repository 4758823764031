define('wnyc-web-client/instance-initializers/eager-load-analytics', ['exports', 'nypr-metrics/instance-initializers/eager-load-analytics'], function (exports, _eagerLoadAnalytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _eagerLoadAnalytics.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _eagerLoadAnalytics.initialize;
    }
  });
});