define('wnyc-web-client/components/google-recaptcha', ['exports', 'nypr-account-settings/components/google-recaptcha'], function (exports, _googleRecaptcha) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleRecaptcha.default;
    }
  });
});