define('wnyc-web-client/discover/edit/shows/route', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    discoverPrefs: Ember.inject.service(),
    titleToken: 'Discover Edit Shows',

    model: function model() {
      var prefs = this.get('discoverPrefs');

      return this.store.query('show', {
        discover_station: _environment.default.discoverStation,
        api_key: _environment.default.discoverAPIKey,
        'fields[show]': 'slug,title,image'
      }).then(function (shows) {
        return Ember.RSVP.hash({
          shows: shows,
          excludedShowSlugs: prefs.get('excludedShowSlugs')
        });
      });
    }
  });
});