define('wnyc-web-client/mirage-models/member-status', ['exports', 'nypr-account-settings/mirage-models/member-status'], function (exports, _memberStatus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _memberStatus.default;
    }
  });
});