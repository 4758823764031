define('wnyc-web-client/helpers/numeric-duration', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numericDuration = numericDuration;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function numericDuration(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        milliseconds = _ref2[0];

    var hours = _moment.default.duration(milliseconds).get('hours');
    var minutes = _moment.default.duration(milliseconds).get('minutes');
    if (hours > 0) {
      minutes = String(minutes).length === 1 ? '0' + minutes : minutes;
    }
    var seconds = _moment.default.duration(milliseconds).get('seconds');
    seconds = String(seconds).length === 1 ? '0' + seconds : seconds;

    return '' + (hours > 0 ? hours + ':' : '') + minutes + ':' + seconds;
  }

  exports.default = Ember.Helper.helper(numericDuration);
});