define('wnyc-web-client/schedule/date/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    googleAds: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return model.get('title');
    },

    queryParams: {
      scheduleStation: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var year = _ref.year,
          month = _ref.month,
          day = _ref.day,
          scheduleStation = _ref.scheduleStation;

      // year, month, day, and scheduleStation will all be available vars
      return this.store.findRecord('django-page', 'schedule/' + year + '/' + month + '/' + day + '/?scheduleStation=' + scheduleStation);
    },
    afterModel: function afterModel() {
      this.get('googleAds').doTargeting();
    }
  });
});