define('wnyc-web-client/mirage-serializers/show', ['exports', 'nypr-publisher-lib/mirage-serializers/show'], function (exports, _show) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _show.default;
    }
  });
});