define('wnyc-web-client/components/action-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['rounded-caps-button'],
    classNameBindings: ['hasIcon:mod-with-icon'],
    attributeBindings: ['data-action', 'data-label', 'data-category', 'data-value'],
    iconPlacement: 'right',
    placeRight: Ember.computed('iconPlacement', function () {
      return this.get('iconPlacement') === 'right';
    }),
    placeLeft: Ember.computed.not('placeRight'),
    hasIcon: Ember.computed('icon', function () {
      return !!this.get('icon');
    })
  });
});