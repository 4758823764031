define('wnyc-web-client/routes/archive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      ordering: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      },
      month: {
        refreshModel: true
      },
      day: {
        refreshModel: true
      }
    },

    titleToken: "Producer's Archive",

    model: function model(_ref) {
      var uslug = _ref.uslug,
          page = _ref.page,
          pageSize = _ref.pageSize,
          ordering = _ref.ordering,
          year = _ref.year,
          month = _ref.month,
          day = _ref.day;

      var fields = 'item_type,slug,newsdate,segments,title,tease';
      var item_type = 'episode,article';
      return this.store.query('story', {
        page: page,
        uslug: uslug,
        ordering: ordering,
        item_type: item_type,
        year: year,
        month: month,
        day: day,
        meta: 'dates',
        page_size: pageSize,
        'fields[story]': fields
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('uslug', this.paramsFor('archive').uslug);
    }
  });
});