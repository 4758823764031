define('wnyc-web-client/breakpoints', ['exports', 'nypr-player/breakpoints'], function (exports, _breakpoints) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var narrow = 321;
  var middleNarrow = 476;
  var middleMiddle = 701;
  var medium = 801;
  var large = 1025;
  var short = 700;

  exports.default = Ember.assign({
    largeAndUp: '(min-width: ' + large + 'px)',
    mediumAndUp: '(min-width: ' + medium + 'px)',
    smallAndUp: '(min-width: ' + narrow + 'px)',
    middleNarrowAndUp: '(min-width: ' + middleNarrow + 'px)',
    middleMiddleAndUp: '(min-width: ' + middleMiddle + 'px)',

    narrowOnly: '(max-width: ' + (narrow - 1) + 'px)',
    middleNarrowOnly: '(max-width: ' + (middleNarrow - 1) + 'px)',
    smallOnly: '(max-width: ' + (medium - 1) + 'px)',
    mediumOnly: '(max-width: ' + (large - 1) + 'px)',

    // VERTICAL
    shortAndUp: '(min-height: ' + short + 'px)',
    shortOnly: '(max-height: ' + short + 'px)'
  }, _breakpoints.default);
});