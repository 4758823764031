define('wnyc-web-client/story/route', ['exports', 'wnyc-web-client/mixins/play-param', 'wnyc-web-client/config/environment'], function (exports, _playParam, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_playParam.default, {
    session: Ember.inject.service(),
    googleAds: Ember.inject.service(),
    dataPipeline: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),

    titleToken: function titleToken(_ref) {
      var story = _ref.story;

      return [Ember.get(story, 'title'), Ember.get(story, 'showTitle') || Ember.get(story, 'channelTitle') || 'NPR Article'];
    },
    model: function model(_ref2, _ref3) {
      var _this = this;

      var slug = _ref2.slug;
      var queryParams = _ref3.queryParams;


      return this.store.findRecord('story', slug, { adapterOptions: { queryParams: queryParams } }).then(function (story) {
        var comments = _this.store.query('comment', { itemTypeId: story.get('itemTypeId'), itemId: story.get('cmsPK') });
        var relatedStories = _this.store.query('story', { related: { itemId: story.get('cmsPK'), limit: 5 } });

        return Ember.RSVP.hash({
          story: story,
          getComments: function getComments() {
            return comments;
          },
          getRelatedStories: function getRelatedStories() {
            return relatedStories;
          },
          adminURL: _environment.default.adminRoot + '/admin'
        });
      });
    },
    afterModel: function afterModel(_ref4, transition) {
      var _this2 = this;

      var story = _ref4.story;

      Ember.get(this, 'googleAds').doTargeting(story.forDfp());

      if (Ember.get(story, 'headerDonateChunk')) {
        transition.send('updateDonateChunk', Ember.get(story, 'headerDonateChunk'));
      }
      Ember.get(this, 'dataLayer').setForType('story', story);

      Ember.run.schedule('afterRender', function () {
        // data pipeline
        Ember.get(_this2, 'dataPipeline').reportItemView({
          cms_id: Ember.get(story, 'cmsPK'),
          item_type: Ember.get(story, 'itemType')
        });
      });
    },
    setupController: function setupController(controller) {
      controller.set('isMobile', window.Modernizr.touchevents);
      controller.set('session', Ember.get(this, 'session'));
      controller.set('user', Ember.get(this, 'currentUser.user'));

      return this._super.apply(this, arguments);
    },
    renderTemplate: function renderTemplate(controller, model) {
      if (Ember.get(model, 'story.template') === 'story_full-bleed') {
        this.send('disableChrome');
        this.render('full-bleed');
      } else {
        this._super.apply(this, arguments);
      }
    },


    actions: {
      willTransition: function willTransition() {
        this.send('enableChrome');
        Ember.get(this, 'dataLayer').clearForType('story');
      }
    }
  });
});