define('wnyc-web-client/components/discover-topic/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isSelected'],
    classNames: ['discover-topic', 'discover-topic-bubble'],
    attributeBindings: ['data-category', 'data-action', 'data-label', 'data-value'],
    tagName: 'label'
  });
});