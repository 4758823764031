define('wnyc-web-client/routes/reset', ['exports', 'wnyc-web-client/mixins/deauthenticated-route-mixin'], function (exports, _deauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_deauthenticatedRouteMixin.default, {
    titleToken: 'Reset Password',

    beforeModel: function beforeModel(transition) {
      if (!transition.queryParams.email || !transition.queryParams.confirmation) {
        // if we got here with missing url parameters,
        // (i.e. typing url, bad copy/paste)
        // send the user to the 'forgot' page so they can request a reset email
        this.transitionTo('forgot');
      } else {
        this._super.apply(this, arguments);
      }
    },

    actions: {
      didTransition: function didTransition() {
        this.send('disableChrome');
      },
      willTransition: function willTransition() {
        this.send('enableChrome');
      }
    }
  });
});