define('wnyc-web-client/instance-initializers/ember-hifi', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var emberHifi = _environment.default.emberHifi,
        _config$environment = _environment.default.environment,
        environment = _config$environment === undefined ? 'development' : _config$environment;

    var options = { emberHifi: emberHifi, environment: environment };
    application.register('config:hifi', options, { instantiate: false });
    application.inject('service:hifi', 'options', 'config:hifi');
  }

  exports.default = {
    name: 'ember-hifi',
    initialize: initialize
  };
});