define('wnyc-web-client/services/page-numbers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    page: 1,
    perPage: 10,
    totalPages: 0
  });
});