define('wnyc-web-client/discover/redirect/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    discoverPrefs: Ember.inject.service(),

    redirect: function redirect() /*model, transition*/{
      var prefs = this.get('discoverPrefs');

      if (!prefs.get('setupComplete')) {
        this.replaceWith('discover.' + prefs.get('currentSetupStep'));
      } else {
        this.replaceWith('discover.index');
      }
    }
  });
});