define('wnyc-web-client/helpers/serial-links', ['exports', 'nypr-ui/helpers/serial-links'], function (exports, _serialLinks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _serialLinks.default;
    }
  });
  Object.defineProperty(exports, 'serialLinks', {
    enumerable: true,
    get: function () {
      return _serialLinks.serialLinks;
    }
  });
});