define('wnyc-web-client/validations/nypr-accounts/login', ['exports', 'nypr-account-settings/validations/nypr-accounts/login'], function (exports, _login) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _login.default;
    }
  });
});