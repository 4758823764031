define('wnyc-web-client/routes/forgot', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Forgot Password',

    setupController: function setupController(controller) {
      controller.set('config', _environment.default);
      return this._super.apply(this, arguments);
    },

    actions: {
      didTransition: function didTransition() {
        this.send('disableChrome');
      },
      willTransition: function willTransition() {
        this.send('enableChrome');
      }
    }
  });
});