define('wnyc-web-client/components/discover-setup-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['discover-setup-header-container'],
    isLoadingBack: Ember.computed.equal('loadingDirection', 'back'),
    isLoadingNext: Ember.computed.equal('loadingDirection', 'next'),
    actions: {
      next: function next() {
        this.sendAction('onNext'); // eslint-disable-line
      },
      back: function back() {
        this.sendAction('onBack'); // eslint-disable-line
      }
    }
  });
});