define('wnyc-web-client/story/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['tab'],
    tab: null,

    setTab: function setTab() {
      if (location.hash.substr(1) === "transcript") {
        this.set("tab", 'transcript');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", this, this.setTab);
      this.set('deviceIsIos', !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
    }
  });
});