define('wnyc-web-client/components/discover-topic-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['discover-topic-list'],
    topicTags: Ember.computed.mapBy('topics', 'url'),

    allSelected: Ember.computed('selectedTopicTags.length', 'topicTags.length', function () {
      return this.get('topics').slice().length === this.get('selectedTopicTags').length;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        topics: this.topics || [],
        selectedTopicTags: this.selectedTopicTags || []
      });
      this.updateTopics(this.selectedTopicTags);
    },
    updateTopics: function updateTopics(topics) {
      var _this = this;

      Ember.run.once(function () {
        _this.set('selectedTopicTags', topics.slice());
        // don't want this bound to the session stuff passed in or saving gets hinky

        /* eslint-disable */
        _this.sendAction('onNoneSelected', topics.length === 0);
        _this.sendAction('onTopicsUpdated', topics);
        /* eslint-enable */
      });
    },


    actions: {
      selectAll: function selectAll() {
        this.updateTopics(this.get('topicTags'));
      },
      selectNone: function selectNone() {
        this.updateTopics([]);
      },
      onMultiselectChangeEvent: function onMultiselectChangeEvent(selectedTopics, value, action) {
        var topics = this.get('selectedTopicTags');

        if (action === 'added') {
          topics.addObject(value);
        } else if (action === 'removed') {
          topics.removeObject(value);
        }

        this.updateTopics(topics);
      }
    }
  });
});