define('wnyc-web-client/components/discover-playlist/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    scroller: Ember.inject.service(),
    dj: Ember.inject.service(),

    classNames: ['discover-playlist-container'],
    classNameBindings: ['isDraggingItem:is-dragging-item'],

    audioReady: Ember.computed.reads('dj.isReady'),
    currentAudioId: Ember.computed.reads('dj.currentContentId'),
    currentlyLoadingIds: Ember.computed.reads('dj.currentlyLoadingIds'),

    isPlaying: Ember.computed.and('audioReady', 'currentTrackIsInPlaylist', 'dj.isPlaying'),

    isPaused: Ember.computed('currentTrackIsInPlaylist', 'isPlaying', function () {
      return this.get('currentTrackIsInPlaylist') && !this.get('isPlaying');
    }),

    isNotStarted: Ember.computed('isPlaying', 'isPaused', function () {
      return !this.get('isPlaying') && !this.get('isPaused');
    }),

    storyIds: Ember.computed.mapBy('stories', 'id'),

    currentTrackIsInPlaylist: Ember.computed('stories', 'currentAudioId', function () {
      return !!this.get('stories').findBy('id', this.get('currentAudioId'));
    }),

    currentPlaylistStoryPk: Ember.computed('currentTrackIsInPlaylist', function () {
      if (this.get('currentTrackIsInPlaylist')) {
        return this.get('currentAudioId');
      }
    }),

    stillVisibleStories: Ember.computed.setDiff('stories', 'removedItems'),
    visibleCount: Ember.computed.alias('stillVisibleStories.length'),
    refreshAutomaticallyIfZero: Ember.observer('visibleCount', function () {
      if (this.get('itemCount') === 0) {
        this.findMore();
      }
    }),

    removedItemIds: Ember.computed.map('removedItems', function (i) {
      return i.id;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      // but by not actually deleting the item from the list we can avoid having to
      // set magic number timeouts
      this.set('removedItems', []);
    },

    // This is for the delete effects, and this might be a weird way to do it
    actions: {
      removeItem: function removeItem(item) {
        // This will trigger the CSS effect to remove it/hide it from the list
        this.get('removedItems').addObject(item);

        // this will fire the listen action and delete it from the queue
        // we don't want to actually delete it from the stories object
        // that will work itself next time the list loads
        this.sendAction('onRemoveItem', item); // eslint-disable-line
      },
      dragStarted: function dragStarted() /* item */{
        this.set('isDraggingItem', true);
      },
      dragStopped: function dragStopped() /* item */{
        this.set('isDraggingItem', false);
      },
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this.set('justDragged', draggedModel);

        // This is a good time to actually delete the hidden items
        var removedItemIds = this.get('removedItemIds');
        var presentAndOrderedItems = itemModels.reject(function (item) {
          return removedItemIds.includes(item.id);
        });

        this.set('stories', presentAndOrderedItems);
        this.set('removedItems', []); // clear out removed/hidden items

        // this sends it up to get updated in the queue
        this.sendAction('onUpdateItems', presentAndOrderedItems); // eslint-disable-line
      },
      toggle: function toggle() {
        var storyPk = this.get('currentPlaylistStoryPk');

        if (this.get('isPlaying')) {
          this.send('pauseTrack');
        } else if (storyPk) {
          this.send('playTrack', storyPk);
        } else {
          var story = this.get('stories').get('firstObject');
          this.send('playTrack', story.id);
          this.get('scroller').scrollVertical(document.querySelector('span[data-story-id="' + story.id + '"]'), { offset: -100, duration: 500 });
        }
      },
      pauseTrack: function pauseTrack() /* storyId */{
        this.get('dj').pause();
      },
      playTrack: function playTrack(pk) {
        this.get('dj').play(pk, { playContext: 'discover' });
      },
      findMore: function findMore() {
        this.sendAction('findMore'); // eslint-disable-line
      }
    }
  });
});