define('wnyc-web-client/models/order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    fund: attr('string'),
    orderPrice: attr('number'),
    orderDate: attr('string'),
    orderCode: attr('string'),
    orderType: attr('string'),
    orderKey: attr('string'),
    premium: attr('string'),
    creditCardType: attr('string'),
    creditCardLast4Digits: attr('string'),
    isActiveMember: attr('boolean'),
    isSustainer: attr('boolean'),
    isPayment: attr('boolean'),
    updateLink: Ember.computed('fund', function () {
      var pledgeDomain = this.get('fund') === 'WQXR' ? 'wqxr' : 'wnyc';
      var fundSlug = this.get('fund').toLowerCase().replace(/[. ]/g, '');
      return 'https://pledge3.' + pledgeDomain + '.org/donate/mc-' + fundSlug;
    })
  });
});