define('wnyc-web-client/components/story-list', ['exports', 'nypr-publisher-lib/components/story-list'], function (exports, _storyList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _storyList.default;
    }
  });
});