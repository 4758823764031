define('wnyc-web-client/models/tout', ['exports', 'nypr-publisher-lib/models/bucket-item', 'ember-data'], function (exports, _bucketItem, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _bucketItem.default.extend({
    audio: attr(),
    body: attr('string'),
    imageMain: attr(),
    title: attr('string'),
    url: attr('string'),
    mainImageEligible: Ember.computed('template', 'imageMain', function () {
      var template = Ember.get(this, 'template');
      var imageWidth = Ember.get(this, 'imageMain.w');
      var imageDisplayFlag = Ember.get(this, 'imageMain.isDisplay');
      if (["story_video", "story_interactive", "story_noimage"].includes(template)) {
        return false;
      } else if (template === 'story_archives') {
        return true;
      } else if (imageWidth >= 800 && imageDisplayFlag === true) {
        return true;
      }
    })
  });
});