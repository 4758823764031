define('wnyc-web-client/services/discover-prefs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    excludedStoryIds: Ember.computed.alias('session.data.discover-excluded-story-ids'),

    setupComplete: false,
    currentSetupStep: 'start',

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        selectedTopicTags: [],
        selectedShowSlugs: []
      });
      this.loadFromSession();
    },
    loadFromSession: function loadFromSession() {
      var session = this.get('session');
      var topics = session.getWithDefault('data.discover-topics', []).slice();
      var shows = session.getWithDefault('data.discover-excluded-shows', []).slice();
      var setupComplete = session.getWithDefault('data.discover-setup-complete', false);
      var currentSetupStep = session.getWithDefault('data.discover-current-setup-step', 'start');
      session.set('data.discover-excluded-story-ids', session.getWithDefault('data.discover-excluded-story-ids', []));

      this.set('selectedTopicTags', topics);
      this.set('excludedShowSlugs', shows);
      this.set('setupComplete', setupComplete);
      this.set('currentSetupStep', currentSetupStep);
    },
    setDefaultShows: function setDefaultShows(slugs) {
      if ((this.get('selectedShowSlugs') || []).length === 0) {
        this.set('selectedShowSlugs', slugs);
      }
    },
    setDefaultTopics: function setDefaultTopics(tags) {
      if ((this.get('selectedTopicTags') || []).length === 0) {
        this.set('selectedTopicTags', tags);
      }
    },
    discard: function discard() {
      this.loadFromSession();
    },
    save: function save() {
      var session = this.get('session');
      session.set('data.discover-excluded-shows', this.get('excludedShowSlugs').slice());
      session.set('data.discover-topics', this.get('selectedTopicTags').slice());
      session.set('data.discover-setup-complete', this.get('setupComplete'));
      session.set('data.discover-current-setup-step', this.get('currentSetupStep'));
    },


    // This works a little differently in that it gets persisted immediately.
    // Not a huge fan of how this is a special case, but oh well.
    excludeStoryId: function excludeStoryId(id) {
      this.get('excludedStoryIds').pushObject(id);
    }
  });
});