define('wnyc-web-client/discover/shows/route', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    discoverPrefs: Ember.inject.service(),
    titleToken: 'Discover Select Shows',

    model: function model() {
      var prefs = this.get('discoverPrefs');

      return this.store.query('show', {
        discover_station: _environment.default.discoverStation,
        api_key: _environment.default.discoverAPIKey,
        'fields[show]': 'slug,title,image'
      }).then(function (shows) {
        return Ember.RSVP.hash({
          shows: shows,
          excludedShowSlugs: prefs.get('excludedShowSlugs')
        });
      });
    },
    setupController: function setupController(controller /*, model*/) {
      this._super.apply(this, arguments);
      controller.set('loadingDirection', null);
    },


    actions: {
      back: function back(excludedShowSlugs) {
        var prefs = this.get('discoverPrefs');
        prefs.set('currentSetupStep', 'topics');
        prefs.set('excludedShowSlugs', excludedShowSlugs);

        this.controller.set('loadingDirection', 'back');
        this.transitionTo('discover.topics');
      },
      next: function next(excludedShowSlugs, hasNotSelectedShow) {
        if (hasNotSelectedShow) {
          this.controller.set('showError', true);
        } else {
          this.controller.setProperties({ showError: false, loadingDirection: 'next' });

          this.controllerFor('discover.shows').set('showError', false);
          var prefs = this.get('discoverPrefs');
          prefs.set('excludedShowSlugs', excludedShowSlugs);
          prefs.set('setupComplete', true);
          prefs.save();

          this.transitionTo('discover.index');
        }
      }
    }
  });
});