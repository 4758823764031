define('wnyc-web-client/transitions/player-reveal', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.newElement.css('visibility', '');
    if (!this.newValue) {
      var player = this.newElement.find('.nypr-player');
      var floatingQueueButton = this.oldElement.find('.nypr-player-queue-button');
      player.css({ transform: 'translateY(80px)' });
      var playerAnimationOptions = { easing: [0.17, 0.89, 0.39, 1.25], duration: 350 };
      return _liquidFire.Promise.all([(0, _liquidFire.animate)(floatingQueueButton, { opacity: [0, 1] }, { duration: 125 }), (0, _liquidFire.animate)(player, { translateY: [0, '80px'] }, playerAnimationOptions)]);
    }
  };
});