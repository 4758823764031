define('wnyc-web-client/discover/route', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    discoverQueue: Ember.inject.service('discover-queue'),
    discoverPrefs: Ember.inject.service(),
    titleToken: 'Discover',

    setupController: function setupController(controller) {
      // Don't use liquid fire in testing until we figure out why
      // it makes acceptance tests fail

      controller.set('useLiquid', _environment.default.environment !== 'testing');
    },
    beforeModel: function beforeModel() {
      if (window.Modernizr.touchevents) {
        // Show download links
        this.replaceWith('discover.start');
      }
    },
    activate: function activate() {
      window.scrollTo(0, 0);
    },

    actions: {
      resetPlaylist: function resetPlaylist() {
        this.get('discoverQueue').emptyQueue();
      }
    }
  });
});