define('wnyc-web-client/models/image', ['exports', 'nypr-publisher-lib/models/image'], function (exports, _image) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _image.default;
    }
  });
});