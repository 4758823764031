define('wnyc-web-client/playlist/route', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect(model, _ref) {
      var params = _ref.params;

      var year = (0, _moment.default)().format('YYYY');
      var month = (0, _moment.default)().format('MMM').toLowerCase();
      var day = (0, _moment.default)().format('DD');
      this.transitionTo('djangorendered', 'playlist-daily/' + year + '/' + month + '/' + day, {
        queryParams: { scheduleStation: params.playlist.slug }
      });
    }
  });
});