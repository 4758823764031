define('wnyc-web-client/helpers/cents-or-whole-number', ['exports', 'nypr-account-settings/helpers/cents-or-whole-number'], function (exports, _centsOrWholeNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _centsOrWholeNumber.default;
    }
  });
  Object.defineProperty(exports, 'centsOrWholeNumber', {
    enumerable: true,
    get: function () {
      return _centsOrWholeNumber.centsOrWholeNumber;
    }
  });
});