define('wnyc-web-client/adapters/order', ['exports', 'ember-data', 'wnyc-web-client/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.membershipAPI,
    namespace: 'v1',
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    },
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url + '/';
    }
  });
});