define('wnyc-web-client/show/route', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'All Shows, Podcasts and Programs',

    googleAds: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        allShows: this.store.query('show', {
          discover_station: _environment.default.showsDiscoverStation,
          api_key: _environment.default.showsAPIKey,
          page_size: 150,
          'fields[show]': 'slug,tease,title,image,producing_organizations'
        })
        // Because of usability conerns, we're commenting this featured show
        // item for now. We want users to access their favorite shows quickly.
        //featured: this.store.findRecord('bucket', 'wnyc-shows-featured').then(b => b.get('bucketItems.firstObject'))
      });
    },
    afterModel: function afterModel() {
      this.get('googleAds').doTargeting();
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.send("resetSearchFilter");
      }
    }

  });
});