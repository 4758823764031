define('wnyc-web-client/components/user-navigation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    isPopupOpen: false,
    classNameBindings: ['isPopupOpen'],
    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});