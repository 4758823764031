define('wnyc-web-client/components/discover-show-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['discover-show-list'],
    showSlugs: Ember.computed.mapBy('shows', 'slug'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        shows: this.shows || [],
        selectedShowSlugs: this.selectedShowSlugs || [],
        excludedShowSlugs: this.excludedShowSlugs || []
      });
      this.updateShows();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.set('selectedShowSlugs', this.get('showSlugs').reject(function (item) {
        return _this.excludedShowSlugs.includes(item);
      }));
      this.updateShows();

      this._super.apply(this, arguments);
    },
    updateShows: function updateShows() {
      var _this2 = this;

      Ember.run.once(function () {
        /* eslint-disable */
        _this2.sendAction('onShowsUpdated', _this2.excludedShowSlugs.slice());
        _this2.sendAction('onNoneSelected', _this2.selectedShowSlugs.length === 0);
        /* eslint-enable */
      });
    },


    actions: {
      onMultiselectChangeEvent: function onMultiselectChangeEvent(shows, value, action) {
        var excludedShowSlugs = this.get('excludedShowSlugs');
        var selectedShowSlugs = this.get('selectedShowSlugs');

        if (action === 'added') {
          selectedShowSlugs.addObject(value);
          excludedShowSlugs.removeObject(value);
        } else if (action === 'removed') {
          selectedShowSlugs.removeObject(value);
          excludedShowSlugs.addObject(value);
        }

        this.updateShows(excludedShowSlugs, selectedShowSlugs);
      }
    }
  });
});