define('wnyc-web-client/components/playlist-play-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['disabled', 'aria-label', 'title', 'isPlaying:data-is-playing'],

    title: Ember.computed('itemTitle', function () {
      return 'Listen to ' + this.get('itemTitle');
    }),
    'aria-label': Ember.computed.alias('title'),

    disabled: Ember.computed.not('isReady'),
    isReady: false,
    isPlaying: false,

    click: function click() {
      if (!this.get('isReady')) {
        return;
      }

      if (this.get('isPlaying')) {
        this.onPause();
      } else {
        this.onPlay();
      }
    }
  });
});