define('wnyc-web-client/components/x-marquee', ['exports', 'nypr-publisher-lib/components/x-marquee'], function (exports, _xMarquee) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _xMarquee.default;
    }
  });
});