define('wnyc-web-client/components/discover-show/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['discover-show'],

    classNameBindings: ['isSelected'],
    attributeBindings: ['slug:data-slug', 'data-category', 'data-action', 'data-label', 'data-value'],

    imageUrl: Ember.computed('imageTemplate', function () {
      var template = this.get('imageTemplate');
      if (template) {
        return template.replace('%s/%s/%s/%s', "200/200/l/80");
      }
    }),

    click: function click() {
      this.toggleProperty('isSelected');
    }
  });
});