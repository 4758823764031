define('wnyc-web-client/components/centennial/centennial-cta-rectangle/component', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('staticAssetPath', _environment.default.staticAssetPath);
    }
  });
});