define('wnyc-web-client/components/site-chrome/component', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        router: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        donateURL: _environment.default.wnycDonateURL,
        classNameBindings: ['showPlayer:sitechrome--player-open'],
        preferredStream: Ember.computed.or('session.data.user-prefs-active-stream', 'defaultStream'),

        init: function init() {
            this._super.apply(this, arguments);
            this.setProperties({
                defaultStream: { slug: 'wnyc-fm939', name: 'WNYC 93.9 FM' },
                socialIcons: [{ url: 'http://www.twitter.com/wnyc', icon: 'twitter' }, { url: 'http://www.facebook.com/wnyc', icon: 'facebook' }, { url: 'https://www.instagram.com/wnyc', icon: 'instagram' }]
            });
        },


        actions: {
            routeSearch: function routeSearch(val) {
                this.get('router').transitionTo('djangorendered', 'search/', { queryParams: { q: val } });
            }
        }
    });
});