define('wnyc-web-client/routes/index', ['exports', 'wnyc-web-client/mixins/play-param', 'nypr-django-for-ember/utils/compat-hooks', 'ember-data'], function (exports, _playParam, _compatHooks, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;


  var STREAM_BG = '/assets/img/backgrounds/streambanner.jpg';

  exports.default = Ember.Route.extend(_playParam.default, {
    classNames: ['home'],
    dj: Ember.inject.service(),
    googleAds: Ember.inject.service(),

    model: function model() {
      var page = this.store.findRecord('django-page', '/');
      // let streams = this.store.findAll('stream', {reload: true});
      var featuredStream = this.store.findRecord('stream', 'wnyc-fm939');
      // let gothamist = fetch(config.gothamistStories)
      //   .then(r => r.json()).then(({entries = []}) => entries.slice(0, 5))
      //   .catch(() => []);

      return hash({ page: page, featuredStream: featuredStream /*, gothamist*/ });
    },
    afterModel: function afterModel() {
      Ember.get(this, 'googleAds').doTargeting();
    },


    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        (0, _compatHooks.beforeTeardown)();
        return true;
      }
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var streams = _emberData.default.PromiseArray.create({
        promise: this.store.findAll('stream', { reload: true }).then(function (s) {
          return s.filterBy('liveWNYC').sortBy('sitePriority').concat(s.filterBy('isWQXR').sortBy('sitePriority')).uniq();
        })
      });
      controller.set('streams', streams);
      controller.set('background', STREAM_BG);
    }
  });
});