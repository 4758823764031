define('wnyc-web-client/controllers/application', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    dj: Ember.inject.service(),
    hifi: Ember.inject.service(),
    session: Ember.inject.service(),
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    queue: Ember.inject.service('listen-queue'),

    queryParams: ['modal', 'play'],
    modal: null,
    play: null,

    showPlayer: Ember.computed.reads('dj.showPlayer'),

    isHomepage: Ember.computed.match('currentRouteName', /^index(_loading)?$/),

    mailchimpEndpoint: _environment.default.optInAPI + '/mailchimp',
    politicsBriefNewsletter: _environment.default.politicsBriefNewsletter,

    actions: {
      showModal: function showModal(which) {
        this._scrollY = window.scrollY;
        this.set('modal', which);
        this._wasModal = true;
      },
      closeModal: function closeModal() {
        if (!this.get('modal')) {
          return;
        }
        window.scrollTo(0, this._scrollY);
        this.set('modal', null);
        this._wasModal = true;
      },
      soundTitleDidChange: function soundTitleDidChange() {
        var sound = this.get('hifi.currentSound');
        if (Ember.get(sound, 'isStream') && Ember.get(sound, 'position') > 10) {
          // skip initial play
          this.get('dataLayer').audioTracking('schedule', sound);
        }
      }
    }
  });
});