define('wnyc-web-client/components/centennial/centennial-share-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('copyText', 'Copy Link');
      this.set('mailtoUrl', 'mailto:?subject=' + this.shareTitle + '&body=' + this.shareTitle + '%0A%0A' + this.shareUrl);
    },

    actions: {
      shareX: function shareX() {
        window.open('https://x.com/intent/tweet?text=' + this.shareTitle + '&url=' + this.shareUrl, 'twitter-share-dialog', 'width=626,height=436');
      },
      shareFacebook: function shareFacebook() {
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + this.shareUrl, 'facebook-share-dialog', 'width=626,height=436');
      },
      updateClipboard: function updateClipboard() {
        var _this = this;

        navigator.permissions.query({ name: "clipboard-write" }).then(function (result) {
          if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard.writeText(_this.shareUrl).then(function () {
              _this.set('copyText', 'Link Copied!');
              setTimeout(function () {
                _this.set('copyText', 'Copy Link');
              }, 2500);
            }).catch(function () {
              _this.set('copyText', 'Error Copying Link');
              setTimeout(function () {
                _this.set('copyText', 'Copy Link');
              }, 2500);
            });
          }
        });
      }
    }
  });
});