define('wnyc-web-client/channel/route', ['exports', 'nypr-django-for-ember/utils/compat-hooks', 'wnyc-web-client/mixins/play-param', 'wnyc-web-client/config/environment', 'ember-inflector'], function (exports, _compatHooks, _playParam, _environment, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);

  exports.default = Ember.Route.extend(_playParam.default, {
    session: Ember.inject.service(),
    googleAds: Ember.inject.service(),

    model: function model(params) {
      var channelPathName = inflector.pluralize(this.routeName.split('-')[0]);
      var listingSlug = channelPathName + '/' + params.slug;
      Ember.set(this, 'listingSlug', listingSlug);

      var channel = this.store.findRecord('channel', listingSlug);
      var listenLive = this.store.findRecord('chunk', 'shows-' + params.slug + '-listenlive').catch(function () {
        return '';
      });

      return Ember.RSVP.hash({
        channel: channel,
        listenLive: listenLive,
        user: this.get('session.data.authenticated')
      });
    },
    afterModel: function afterModel(_ref, transition) {
      var channel = _ref.channel;

      if (channel) {
        var canonicalHost = Ember.get(channel, 'canonicalHost');
        if (canonicalHost && canonicalHost !== document.location.host) {
          transition.abort();
          window.location.href = Ember.get(channel, 'url');
          return;
        }
      }
      Ember.get(this, 'googleAds').doTargeting({ show: channel.get('slug') });
      if (channel.get('headerDonateChunk')) {
        transition.send('updateDonateChunk', channel.get('headerDonateChunk'));
      }
      if (channel.get('altLayout')) {
        transition.send('setMiniChrome', true);
      }
    },
    setupController: function setupController(controller, model) {
      var _paramsFor = this.paramsFor(this.routeName + '.page'),
          _paramsFor$page_param = _paramsFor.page_params,
          page_params = _paramsFor$page_param === undefined ? '' : _paramsFor$page_param;

      var _page_params$split = page_params.split('/'),
          _page_params$split2 = _slicedToArray(_page_params$split, 1),
          navSlug = _page_params$split2[0];

      controller.setProperties({
        channelType: this.routeName,
        defaultSlug: navSlug,
        model: model,
        session: Ember.get(this, 'session'),
        adminURL: _environment.default.adminRoot + '/admin'
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        var isExiting = !transition.targetName.match(this.routeName);
        this._super.apply(this, arguments);
        (0, _compatHooks.beforeTeardown)();
        if (Ember.get(this, 'currentModel.channel.altLayout') && isExiting) {
          transition.send('setMiniChrome', false);
        }
        if (isExiting) {
          Ember.get(this, 'googleAds').clearTarget('show');
        }
        return true;
      }
    }
  });
});