define('wnyc-web-client/mixins/query-method', ['exports', 'ember-query-method/mixins/query-method'], function (exports, _queryMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _queryMethod.default;
    }
  });
});