define('wnyc-web-client/transitions/to-left-with-reset', ['exports', 'wnyc-web-client/transitions/move-over', 'wnyc-web-client/utils/fix-positioning-after-transition', 'velocity'], function (exports, _moveOver, _fixPositioningAfterTransition, _velocity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    var el = document.getElementsByTagName('html');

    return (0, _velocity.default)(el, 'scroll', { offset: 0, duration: 100 }).then(function () {
      return _moveOver.default.call(_this, 'x', -1).then(function () {
        (0, _fixPositioningAfterTransition.default)();
      });
    });
  };
});