define('wnyc-web-client/adapters/django-page', ['exports', 'nypr-django-for-ember/adapters/django-page'], function (exports, _djangoPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _djangoPage.default;
    }
  });
});