define('wnyc-web-client/discover/edit/topics/route', ['exports', 'wnyc-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    discoverPrefs: Ember.inject.service(),
    titleToken: 'Discover Edit Topics',

    model: function model() {
      return Ember.RSVP.hash({
        topics: this.store.query("discover.topics", { discover_station: _environment.default.discoverTopicsKey }),
        selectedTopicTags: this.get('discoverPrefs.selectedTopicTags')
      });
    },

    actions: {}
  });
});