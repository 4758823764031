define('wnyc-web-client/models/stream', ['exports', 'nypr-publisher-lib/models/stream'], function (exports, _stream) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _stream.default.extend({
    shareMetadata: Ember.computed('currentShow', 'currentPlaylistItem', function () {
      var shareText = '';
      var shareUrl = '';
      var via = Ember.get(this, 'twitterHandle') || 'WNYC';

      var entry = Ember.get(this, 'currentPlaylistItem.catalogEntry');
      if (entry && entry.composer) {
        shareText = entry.title + ' - ' + entry.composer.name;
        shareUrl = 'http://www.wnyc.org/streams/' + Ember.get(this, 'slug');
      } else {
        shareText = Ember.get(this, 'currentShow.title');
        shareUrl = Ember.get(this, 'currentShow.url');
      }

      return { shareText: shareText, shareUrl: shareUrl, via: via };
    })
  });
});