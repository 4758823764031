define('wnyc-web-client/show/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    filteredShows: Ember.computed('model.allShows', 'searchText', function () {
      var searchText = Ember.get(this, 'searchText');
      var shows = Ember.get(this, 'model.allShows');
      var result = shows.toArray();
      if (searchText.length > 0) {
        var query = searchText.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'); //remove any regex characters
        var regex = new RegExp(query, 'i'); //case insensitive
        result = shows.filter(function (show) {
          return regex.test(show.get('title'));
        });
      }
      return result.sortBy('sortableTitle');
    }),
    noResults: Ember.computed.equal('filteredShows.length', 0),
    searchText: '',

    actions: {
      resetSearchFilter: function resetSearchFilter() {
        this.set("searchText", "");
      },
      filterShows: function filterShows(val) {
        this.set("searchText", val);
      }
    }
  });
});