define('wnyc-web-client/routes/centennial', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var archiveItems = this.store.findRecord('bucket', 'wnyc-centennial-archives').then(function (b) {
        return b.get('bucketItems');
      });
      var featuredCategory1 = this.store.findRecord('bucket', 'centennial-featured-content-1');
      var featuredCategory2 = this.store.findRecord('bucket', 'centennial-featured-content-2');
      var featuredCategory3 = this.store.findRecord('bucket', 'centennial-featured-content-3');
      var events = this.store.findRecord('bucket', 'wnyc-centennial-events').then(function (b) {
        return b.get('bucketItems');
      });

      return Ember.RSVP.hash({
        archiveItems: archiveItems,
        featuredCategory1: featuredCategory1,
        featuredCategory2: featuredCategory2,
        featuredCategory3: featuredCategory3,
        events: events
      });
    }
  });
});