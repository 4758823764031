define('wnyc-web-client/routes/404', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),

    beforeModel: function beforeModel() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        _this.get('dataLayer').send404();
      });
    }
  });
});