define('wnyc-web-client/components/discover-playlist-story-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    moment: Ember.inject.service(),
    classNames: ['discover-playlist-story-info'],
    showSummary: false,

    actions: {
      toggleSummary: function toggleSummary() {
        this.toggleProperty('showSummary');
      }
    }
  });
});